import useResponsive from '../../hooks/useResponsive';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// components
import { MotionContainer, TextAnimate, varFade } from '../../components/animate';
import { FONT_TITLE } from 'src/theme/typography';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage:
    'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1680279021/NextGen/group_u6r4bu.jpg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    // backgroundImage: 'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1680440101/NextGen/overlay_static2_kace1d.png)',
    // opacity: 0.5
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function ConferenceDetailsHero() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <RootStyle>
      <Container component={MotionContainer} sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          <Box sx={{ display: 'block', color: 'common.white' }}>
            <TextAnimate text="Conference" sx={{ mr: 2, fontFamily: FONT_TITLE }} />
            <TextAnimate text="Details" sx={{ fontFamily: FONT_TITLE}} />
          </Box>

          {isDesktop && (
            <m.div>
              <Typography
                variant="h1"
                sx={{
                  mt: 5,
                  display: 'inline-flex',
                  color: 'common.white',
                  fontWeight: 'fontWeightMedium',
                }}
              ></Typography>
            </m.div>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
