// @mui
import { Typography, Box, Paper } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'How to read the Bible for themselves',
    icon: 'https://i.ibb.co/DkSBWB1/read.jpg',
    href: '#',
  },
  {
    label: 'How to prepare a Bible study for small groups',
    icon: 'https://i.ibb.co/LRhws3V/teach.jpg',
    href: '#',
  },
  {
    label: 'How to apply the Bible faithfully in their lives',
    icon: 'https://i.ibb.co/RYdFKNK/apply.jpg',
    href: '#',
  },
  {
    label: 'Practical ministry skills',
    icon: 'https://i.ibb.co/tX0FKQB/practical.jpg',
    href: '#',
  },
];

// ----------------------------------------------------------------------

export default function AboutTaught() {
  return (
    <Box
      sx={{
        mb: 15,
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(4, 1fr)',
        },
      }}
    >
      {CATEGORIES.map((category) => (
        <MotionInView key={category.label} variants={varFade().in}>
          <CategoryCard category={category} />
        </MotionInView>
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
    icon: string;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label, icon } = category;

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        height: 260,
        border: 'none',
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        // '&:hover': {
        //   boxShadow: (theme) => theme.customShadows.z24,
        // },
      }}
    >
      <Image visibleByDefault disabledEffect src={icon} sx={{ mb: 2, width: 150, height: 120 }} />
      <Typography sx={{ fontSize: 19 }}>
        {label}
      </Typography>
    </Paper>
  );
}
