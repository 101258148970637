// @mui
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
// components
import Page from '../components/Page';
// sections
import { ConferenceDetailsHero, ConferenceDetailsWhat } from '../sections/conference-details';
import { FaqsList } from 'src/sections/speaker';
import { useAtomValue } from 'jotai';
import { myAtom } from 'src/pages/Home';
import Maintenance from 'src/pages/Maintenance';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(8),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(11),
  },
}));

// ----------------------------------------------------------------------

export default function ConferenceDetails() {
  const isUnderMaintenance = useAtomValue(myAtom);
  return (
    <Page title="Conference Details">
      <RootStyle>
        {isUnderMaintenance && <Maintenance />}
        {!isUnderMaintenance && (
          <>
            <ConferenceDetailsHero />
            <ConferenceDetailsWhat />
            <FaqsList />
            <Divider orientation="vertical" sx={{ my: 10, mx: 'auto', width: 2, height: 40 }} />
          </>
        )}
      </RootStyle>
    </Page>
  );
}
