// @mui
import { Typography, Box, Paper } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'Aged 18 to 36 years old',
    href: '#',
  },
  {
    label: 'Serving at a local church in Malaysia',
    href: '#',
  },
  //   {
  //     label: 'Practical ministry skills',
  //     href: '#',
  //   },
];

// ----------------------------------------------------------------------
// MOBILE ONLY
export default function AboutIfYouAre() {
  return (
    <>
      <Box
        sx={{
          mb: 10,
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 1fr)',
          },
        }}
      >
        {CATEGORIES.map((category) => (
          <MotionInView key={category.label} variants={varFade().in}>
            <CategoryCard category={category} />
          </MotionInView>
        ))}
      </Box>
      <Box
        sx={{
          mb: 10,
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
            lg: 'repeat(1, 1fr)',
          },
        }}
      >
        <Paper
          variant="outlined"
          sx={{
            px: 2,
            mt: -6,
            height: 520,
            borderRadius: 2,
            display: 'flex',
            textAlign: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            '&:hover': {
              boxShadow: (theme) => theme.customShadows.z24,
            },
          }}
        >
          <Typography sx={{ fontSize: 19 }}>
            Serving in any one of the following ministries:
            <br /> <br />
            • Children’s ministry
            <br /> <br />
            • Youth/Teens’ ministry
            <br /> <br />
            • University College ministry
            <br /> <br />
            • Christian Fellowship / Union
            <br /> <br />• Any Christian organization
          </Typography>
        </Paper>{' '}
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label } = category;

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        height: 120,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Typography sx={{ fontSize: 19 }}>{label}</Typography>
    </Paper>
  );
}
