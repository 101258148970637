import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0,
  },
}));

const ScreenStyle = styled(MotionInView)(({ theme }) => ({
  paddingRight: 2,
  paddingBottom: 1,
  maxWidth: 160,
  borderRadius: 8,
  backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
  [theme.breakpoints.up('sm')]: {
    maxWidth: 320,
    paddingRight: 4,
    borderRadius: 12,
  },
  '& img': {
    borderRadius: 8,
    [theme.breakpoints.up('sm')]: {
      borderRadius: 12,
    },
  },
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

const variantScreenLeft = {
  initial: COMMON,
  animate: { ...COMMON, translateX: '-50%', translateY: 40, opacity: 1 },
};
const variantScreenCenter = {
  initial: COMMON,
  animate: { ...COMMON, opacity: 1 },
};
const variantScreenRight = {
  initial: COMMON,
  animate: { ...COMMON, translateX: '50%', translateY: -40, opacity: 1 },
};

// ----------------------------------------------------------------------

export default function HomePartners() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isRTL = theme.direction === 'rtl';

  const isDesktop = useResponsive('up', 'md');
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  const screenLeftAnimate = variantScreenLeft;
  const screenCenterAnimate = variantScreenCenter;
  const screenRightAnimate = variantScreenRight;

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentStyle>
              <MotionInView variants={varFade().inUp}>
                <Typography
                  component="div"
                  variant="h2"
                  sx={{ mb: 2, color: 'text.disabled', textTransform: 'uppercase' }}
                >
                  Partners
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography variant="h3" sx={{ mb: 3, textTransform: 'uppercase' }}>
                  KCC NextGen
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography
                  sx={{
                    mb: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                    fontSize: 19
                  }}
                >
                  Since 1990, KCC NextGen has trained over 14,000 next generation gospel leaders to
                  faithfully pass on the gospel to others. KCC NextGen is a ministry of the Katoomba
                  Christian Convention, a Bible-preaching convention ministry that was founded in
                  1903 in the tradition of the Keswick Convention in the north of England.
                </Typography>
              </MotionInView>

              {/* <MotionInView variants={varFade().inUp}>
                <Button
                  size="large"
                  color="inherit"
                  variant="outlined"
                  component={RouterLink}
                  to={PATH_PAGE.components}
                >
                  View All Components
                </Button>
              </MotionInView> */}
            </ContentStyle>
          </Grid>

          {/* mobile */}
          {!isDesktop && (
            <Grid item xs={12} md={8} dir="ltr">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  justifyContent: 'center',
                }}
              >
                {[...Array(3)].map((_, index) => (
                  <ScreenStyle
                    key={index}
                    threshold={0.72}
                    variants={{
                      ...(index === 0 && screenLeftAnimate),
                      ...(index === 1 && screenCenterAnimate),
                      ...(index === 2 && screenRightAnimate),
                    }}
                    transition={{ duration: 0.72, ease: 'easeOut' }}
                    sx={{
                      boxShadow: `${isRTL ? -80 : 80}px -40px 80px ${alpha(
                        isLight ? theme.palette.grey[600] : theme.palette.common.black,
                        0.48
                      )}`,
                      ...(index === 0 && {
                        zIndex: 3,
                        position: 'absolute',
                      }),
                      ...(index === 1 && { zIndex: 2 }),
                      ...(index === 2 && {
                        zIndex: 1,
                        position: 'absolute',
                        boxShadow: 'none',
                      }),
                    }}
                  >
                    <Image
                      disabledEffect
                      alt={`screen ${index + 1}`}
                      src={`https://res.cloudinary.com/dscrdg0ou/image/upload/v1678517247/NextGen/KCC-logo_rtyl3b.png`}
                    />
                  </ScreenStyle>
                ))}
              </Box>
            </Grid>
          )}
          {isDesktop && (
            <Grid item xs={12} md={6} lg={6} sx={{ pr: { md: 7 } }}>
              <Grid container spacing={3} alignItems="flex-end">
                {/* <Grid item xs={6}>
                  <MotionInView variants={varFade().inUp}>
                    <Image
                      src="https://nextgen.kvbctrust.org/wp-content/uploads/2020/02/KNG2020-Instagram.png"
                      ratio="3/4"
                      sx={{
                        borderRadius: 2,
                        boxShadow: shadow,
                      }}
                    />
                  </MotionInView>
                </Grid> */}
                <Grid item xs={3}></Grid>
                <Grid item xs={8}>
                  <MotionInView variants={varFade().inUp}>
                    <Image
                      src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678517247/NextGen/KCC-logo_rtyl3b.png"
                      ratio="1/1"
                      sx={{ borderRadius: 2 }}
                    />
                  </MotionInView>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Container>
    </RootStyle>
  );
}
