import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Grid, Container, Typography, LinearProgress, Paper, Button } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { fPercent } from '../../utils/formatNumber';
// _mock_
import { _skills } from '../../_mock';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import ConferenceDetailsBulletPoint from './ConferenceDetailsBulletPoint';
import ConferenceDetailsCost from './ConferenceDetailsCost';
import { HashLink as Link } from 'react-router-hash-link';
import { FONT_SECONDARY, FONT_TERTIARY } from 'src/theme/typography';
import ButtonRegister from 'src/components/ButtonRegister';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

// ----------------------------------------------------------------------

export default function ConferenceDetailsWhat() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const isLight = theme.palette.mode === 'light';
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  const schedulePic = 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1715307581/NextGen/2024/SCHEDULE_KVBC-2024_m38irj.jpg'

  return (
    <RootStyle>
      <Container>
        <Box
          sx={{
            mb: 10,
            position: 'relative',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          {/* <Image
            src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515542/NextGen/Conference-Schedule_sfbghx.jpg"
            alt="about-vision"
            effect="black-and-white"
          /> */}
        </Box>
      </Container>

      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 3 } }}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={6}>
                <Image
                  src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680360401/NextGen/group7_p5ewwe.jpg"
                  ratio="4/3"
                  sx={{
                    borderRadius: 2,
                    boxShadow: shadow,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Image
                  src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680779848/NextGen/Conf_Details_juiksn.jpg"
                  ratio="1/1"
                  sx={{ borderRadius: 2 }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5} sx={{ mt: -1, fontSize: 19 }} >
            {/* <Typography variant="h3" sx={{ mb: 1 }}>
              CONFERENCE DETAILS
            </Typography> */}

       
              <Typography variant="overline" sx={{ fontFamily: FONT_SECONDARY, fontSize: 15 }}>
                Date
                <br />{' '}
              </Typography>
              22nd May - 25th May 2024
              <br />
              <Typography variant="overline" sx={{ fontFamily: FONT_SECONDARY, fontSize: 15 }}>
                Books
                <br />{' '}
              </Typography>
              Ezekiel & Titus <br />
              <Typography variant="overline" sx={{ fontFamily: FONT_SECONDARY, fontSize: 15 }}>
                Closing date
                <br />{' '}
              </Typography>
              5th May 2024 (Sunday)
              <br />
              <Typography variant="overline" sx={{ fontFamily: FONT_SECONDARY, fontSize: 15 }}>
                Venue
                <br />{' '}
              </Typography>
              Malaysia Bible Seminary (MBS), Rawang
              <Typography sx={{ fontFamily: FONT_SECONDARY, mt: 1 }}>
                Conference begins at 10am on 22nd May 2024
                <br /> and will end at 2pm on 25th May 2024.
                <br />
              </Typography>
              <Typography sx={{ fontFamily: FONT_SECONDARY, mt: 1, mb: 5 }}>
                Need more information? <Link to="#faq">Check out our FAQ below!</Link>{' '}
              </Typography>{' '}

            {/* <Box sx={{ my: 5 }}>
              {_skills.map((progress) => (
                <MotionInView key={progress.label} variants={varFade().inUp}>
                  <ProgressItem progress={progress} />
                </MotionInView>
              ))}
            </Box> */}

            {/* <MotionInView variants={varFade().inUp}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                endIcon={<Iconify icon={'ic:round-arrow-right-alt'} width={24} height={24} />}
              >
                Check out our work
              </Button>
            </MotionInView> */}
          </Grid>
        </Grid>

        <Box
          sx={{
            bottom: { xs: 24, md: 80 },
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            // position: 'absolute',
            justifyContent: 'center',
          }}
        >
          <m.div variants={varFade().inUp}>
            <ButtonRegister />
          </m.div>
        </Box>

        <Grid item mt={12}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ fontFamily: FONT_SECONDARY }}>
              SCHEDULE
            </Typography>
            <hr />
            <a
              href={schedulePic}
              target="_blank"
            >
              <Image
                mt={5}
                src={schedulePic}
              />
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ mt: 5, mb: 5, justifyContent: 'center', alignContent: 'center' }}
        >
          <Grid item>
            <MotionInView variants={varFade().inUp}>
              <Paper
                variant="outlined"
                sx={{
                  px: 8,
                  mt: 5,
                  height: isDesktop ? 600 : 700,
                  borderRadius: 2,
                  borderColor: isDesktop ? '' : 'white',
                  display: 'flex',
                  textAlign: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  boxShadow: (theme) => theme.customShadows.z24,
                }}
              >
                <Typography variant="h3" sx={{ fontFamily: FONT_SECONDARY }}>
                  COST
                </Typography>
                <Typography variant="overline" sx={{ fontFamily: FONT_SECONDARY }}>
                  per pax
                </Typography>

                <Grid sx={{ display: 'flex' }}>
                  <Grid item xs={12} md={12} lg={12} sx={{ mt: 5, mr: 3 }}>
                    <Typography variant="h3">Dorm Room</Typography>
                    <Typography sx={{ mb: 3, fontSize: 19 }}>
                      RM <span style={{ fontSize: 40, fontWeight: 'bold' }}>400</span>
                    </Typography>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={6} sx={{ mt: 5, ml: 3, mb: 5 }}>
                    <Typography variant="h3">
                      Twin <br />
                      Room
                    </Typography>
                    <Typography sx={{ mb: 3, fontSize: 19 }}>
                      RM <span style={{ fontSize: 40, fontWeight: 'bold' }}>450</span>
                    </Typography>
                  </Grid> */}
                </Grid>

                {/* <Typography variant="h3">For</Typography> */}
                <ConferenceDetailsCost />
              </Paper>
            </MotionInView>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 15, mb: 10 }}>
          <Grid item xs={12} md={6} lg={6} sx={{ pr: { md: 7 } }}>
            <Typography variant="h3" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
              PAYMENT DETAILS
            </Typography>
            <Typography sx={{ mb: 3, fontSize: 19 }}>
              Payment to be made to KVBC Trust’s bank account as stated below.
            </Typography>

            <Typography sx={{ fontSize: 19 }}>
              <b>Account Name</b> : CEP Centre Bhd <br />
              <b>Account Number</b> : 140-3700-1012-6372 <br />
              <b>Bank Name </b>: Alliance Bank Berhad <br />
              <b>Payment Details </b>: NGE [name of delegate] <br /> <br />
              <i>
                Please include in the payment details “NGE” (which stands for NextGen English) and
                delegate’s name for our reference. (e.g. NGE John Doe)
              </i>
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            sx={{ pr: { md: 7 }, justifyContent: 'flex-end', alignItems: 'flex-end' }}
          >
            <Grid container spacing={3} alignItems="flex-end" justifyContent="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFade().inUp}>
                  <ConferenceDetailsBulletPoint />
                  {/* <Image
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678516907/NextGen/QRDuitNow_oelirk.png"
                    ratio="3/4"
                    sx={{
                      borderRadius: 2,
                      boxShadow: shadow,
                    }}
                  /> */}
                </MotionInView>
              </Grid>
              <Grid item xs={6}>
                <MotionInView variants={varFade().inUp}>
                  {/* <Image
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678516905/NextGen/QRTouchNGo_mbabtk.png"
                    ratio="3/4"
                    sx={{ borderRadius: 2 }}
                  /> */}
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <ConferenceDetailsBulletPoint /> */}
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' },
        }}
      />
    </Box>
  );
}
