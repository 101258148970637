import { useRef } from 'react';
// @mui
import {
  Accordion,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Container,
} from '@mui/material';
// _mock_
import { _faqs } from '../../_mock';
// components
import Iconify from '../../components/Iconify';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

export default function FaqsList() {
  const targetRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <Container id="faq" ref={targetRef}>
        <Typography variant="h3" sx={{ mb: 3, textTransform: 'uppercase', fontFamily: FONT_SECONDARY }}>
          Frequently asked questions
        </Typography>
        {_faqs.map((accordion, index) => (
          <Accordion key={accordion.id} defaultExpanded>
            <AccordionSummary
              expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}
            >
              <Typography variant="h5">{accordion.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {' '}
                <span style={{ whiteSpace: 'pre-line', fontSize: 19 }}>{accordion.detail}</span>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </>
  );
}
