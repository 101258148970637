// @mui
import { Typography, Box, Paper } from '@mui/material';
// components
import { MotionInView, varFade } from '../../components/animate';
import useResponsive from 'src/hooks/useResponsive';
// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'bt',
    description: <span>7 Bible Talks</span>,
    href: '#',
  },
  {
    label: 'dt',
    description: <span>4 Days’ Training</span>,
    href: '#',
  },
  {
    label: 'pmw',
    description: <span>Practical  <br/> Ministry  Workshops </span>,
    href: '#',
  },
  {
    label: 'cm',
    description: <span>Conference Materials</span>,
    href: '#',
  },
];

// ----------------------------------------------------------------------

export default function ConferenceDetailsCost() {
  return (
    <Box
      sx={{
        mb: 5,
        display: 'grid',
        gap: 2,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
        },
      }}
    >
      {CATEGORIES.map((category) => (
        <MotionInView key={category.label} variants={varFade().in}>
          <CategoryCard category={category} />
        </MotionInView>
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
    description: any;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label, description } = category;
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        height: !isDesktop && label === 'pmw' ? 120 : 60,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: (theme) => theme.customShadows.primary,
      }}
    >
      <Typography sx={{ fontSize: 19 }}>{description}</Typography>
    </Paper>
  );
}
