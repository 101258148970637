import { useLocation, Outlet } from 'react-router-dom';
import { useContext } from 'react';
// @mui
import { Box, Link, Container, Typography, Stack, Grid } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import SocialsButton from '../../components/SocialsButton';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';
import { useAtomValue } from 'jotai';
import { myAtom } from 'src/pages/Home';
export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const isUnderMaintenance = useAtomValue(myAtom);

  return (
    <Stack sx={{ minHeight: 1 }}>
      {!isUnderMaintenance && <MainHeader />}

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />

      {/* {!isHome ? (
        <MainFooter />
      ) : ( */}
      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <Logo sx={{ mb: 1, mx: 'auto' }} />

          <Typography variant="body2">KVBC NextGen is a ministry of KVBC Trust.</Typography>

          <SocialsButton sx={{ mx: 0.5 }} />

          <Typography variant="caption" component="p">
            2024
            {/* <br />KVBC NEXTGEN &nbsp; */}
          </Typography>
        </Container>
      </Box>
      {/* )} */}
    </Stack>
  );
}
