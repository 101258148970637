import { useEffect } from 'react';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Container, Typography, Grid } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { FONT_TERTIARY } from 'src/theme/typography';
import ButtonRegister from 'src/components/ButtonRegister';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 456,
  margin: 'auto',
  overflow: 'hidden',
  paddingBottom: theme.spacing(10),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  backgroundImage: `linear-gradient(135deg,
    ${theme.palette.primary.main} 0%,
    ${theme.palette.primary.dark} 100%)`,
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    maxWidth: '100%',
    paddingBottom: 0,
    alignItems: 'center',
  },
}));

// ----------------------------------------------------------------------

export default function HomeAdvertisement() {

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.curator.io/published/c121ae6a-9e13-42a4-bcd5-64e27db63554.js';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Container sx={{ mb: 10 }}>
        <ContentStyle>
          <Box
            sx={{
              pt: 8,
              pb: { md: 10 },
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            <MotionInView sx={{ color: 'common.white' }}>
              <Typography variant="h3" sx={{ pl: { md: 10 } }}>
                KVBC NextGen 2024
              </Typography>
              <Typography sx={{ px: { xs: 3, md: 10 }, pb: 5, pt: 3, fontSize: 19 }}>
                {/* After 3 years of virtual conferences, our annual training conference will be held
              physically this year!  */}
                Join us for a 4-day training conference that seeks to equip Christian leaders in
                children, youth and campus ministries throughout Malaysia; to grow in godliness, in
                faithfully handling and teaching the Bible to others, and in bringing up godly
                leaders of the next generation. Sign up today!
              </Typography>
            </MotionInView>
            <MotionInView variants={varFade().inDown} sx={{ pl: { md: 10 } }}>
              <ButtonRegister />
            </MotionInView>
          </Box>
        </ContentStyle>
      </Container>

      <Container>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={12} lg={12}>
          <div id="curator-feed-new-feed-layout">
            <a
              href="https://curator.io"
              target="_blank"
              rel="noreferrer"
              className="crt-logo crt-tag"
            ></a>
          </div>
        </Grid>
      </Grid>
      </Container>
    </>
  );
}
