// @mui
import React, { useState } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Grid, Button, Paper, Rating, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import cssStyles from '../../utils/cssStyles';
// components
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

const TESTIMONIALS = [
  {
    name: ' Hannah Pak',
    rating: 5,
    dateCreate: ' (Tabernacle of Grace Bukit Mertajam)',
    content: `NextGen has renewed my excitement for God’s word and how I can guide the youths under my care with the Word of God. I've learned that it's really not by my might, my intelligence and my understanding in the Word of God when it comes to guiding the youth in this area, but it's by the Holy Spirit. Praise God for that!`,
  }, 
  {
    name: 'Emily Hiong ',
    rating: 5,
    dateCreate: '(Malaysia Discipleship Center)',
    content: `Learning how to study the Bible through a Christ-centred perspective has made a difference in the way I craft bible study materials and conduct personal quiet time, as there is no part of the Bible that can be understood without Christ's redemptive work!`,
  },
  {
    name: 'Clarissa Chung',
    rating: 5,
    dateCreate: '(St Mary’s Cathedral KL)',
    content: `It's easy to become complacent and short-sighted in our own little ministries in our churches. NextGen challenged me to refine the way I read the Bible and teach God's word to others. It has also been an important platform to meet brothers and sisters from other churches so we can partner in the gospel in Malaysia.`,
  },
  {
    name: 'Ivan Khoo',
    rating: 5,
    dateCreate: '(First Baptist Church Subang)',
    content: `For my own quiet time, I have this habit of 'questioning' when it comes to Scripture. I'm no longer reading portions of Scripture as it is, but I'm now learning to consider the context (before and after), author's intention and how the author would want his readers (original and now) to respond. As for the ministry I serve, although I'm not actively writing my own studies, the skills that I've learnt from NextGen now pushes me to ask a few questions when new study materials are received (eg: Is the study faithful to the context? How is the study packaged? What’s the big idea?)`,
  },
  {
    name: 'Nicholas Leong',
    rating: 5,
    dateCreate: '(Damansara Utama Methodist Church)',
    content: `Through NextGen, I learned how to read the Bible and see what God is saying to us today. Nextgen has taught me the tools to understand God's Word and how to help others understand it. The model is so helpful and easy to teach, that we've taught it to our cell group and I've been using it to equip others with tools to read the Bible. Since then, I've been very enthusiastic about helping others not just know what the Bible says but to discover it for themselves. More than that, NextGen has also exemplified for me godly and missional living, shaped by a conviction of the gospel. I'm very grateful for this opportunity to grow and be challenged in Bible literacy and in godliness.`,
  },

  {
    name: 'Ewan Lim',
    rating: 5,
    dateCreate: '(Damansara Utama Methodist Church)',
    content: `One of the highlights of NextGen would definitely be the preparation of the Bible studies. Some of my friends and I were in strand one together, and struggling to understand Colossians as a group was really encouraging; partnering together with brothers and sisters to know what Paul, and therefore God, says through the passage, was a really upbuilding experience! NextGen has also helped me articulate how to help people read their bibles. I felt I could read it for myself, but did not know how to properly explain to someone else how they ought to do so. The conference helped me to grow in explaining this in simple yet understandable terms!`,
  },
  {
    name: 'Victor Yeow ',
    rating: 5,
    dateCreate: '(St Mary’s Cathedral KL)',
    content: `When it came to the Bible Talk sessions, we were given a taste of expository preaching on both the Old and New Testament passages. We were given insights on how the books were structured, and above all, how God was faithful to His promises from the Old to the New Testament. KVBC NextGen gives you a great get-away with God and fellow brothers and sisters-in-Christ, to just soak in His wonderful and rich Word, as He reveals Himself a little more to you!`,
  },
];

interface RootStyleProps {
  height?: boolean;
}

const RootStyle = styled('div')<RootStyleProps>(({ theme, height }) => ({
  textAlign: 'center',
  padding: theme.spacing(10, 0),
  backgroundSize: 'cover',
  backgroundImage: `linear-gradient(to right, ${alpha(theme.palette.grey[900], 0.8)} , ${alpha(
    theme.palette.grey[900],
    0.8
  )}), url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1680754839/NextGen/BG_Landscape-01_xgimko.png)`,
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    padding: 0,
    height: height ? 1640 : 880,
    overflow: 'hidden',
  },
}));

// ----------------------------------------------------------------------

export default function AboutTestimonials() {
  const isDesktop = useResponsive('up', 'md');
  const [readMoreTestimonals, setreadMoreTestimonals] = useState(false);

  const handleTestimonial = () => {
    setreadMoreTestimonals(!readMoreTestimonals);
  };

  return (
    <RootStyle height={readMoreTestimonals}>
      <Container sx={{ position: 'relative', height: '100%' }}>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ height: '100%' }}
        >
          <Grid item xs={10} md={4}>
            <Box sx={{ maxWidth: { md: 360 } }}>
              <MotionInView variants={varFade().inUp}>
                <Typography
                  component="p"
                  variant="overline"
                  sx={{ mb: 2, color: 'text.secondary' }}
                ></Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography variant="h2" sx={{ mb: 3, color: 'common.white', fontFamily: FONT_SECONDARY }}>
                  Testimonials
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography sx={{ color: 'common.white' }}>
                  Listen to what our delegates have to say!
                </Typography>
              </MotionInView>
              {isDesktop && (
                <Box sx={{ mt: 3 }}>
                  <MotionInView variants={varFade().inLeft}>
                    {/* <TestimonialLink /> */}
                    <Button
                      onClick={handleTestimonial}
                      sx={{ display: 'flex', alignItems: 'center', fontSize: 19, color: 'gray' }}
                    >
                      {readMoreTestimonals ? 'Show Less' : 'Read more testimonials'}
                      <Iconify
                        icon={'ic:round-arrow-right-alt'}
                        sx={{ ml: 1, width: 20, height: 20 }}
                      />
                    </Button>
                  </MotionInView>
                </Box>
              )}

              {!isDesktop && (
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <MotionInView variants={varFade().inUp}>
                    {/* <TestimonialLink /> */}
                    <Button
                      onClick={handleTestimonial}
                      sx={{ display: 'flex', alignItems: 'center', fontSize: 19 }}
                    >
                      {readMoreTestimonals ? 'Show Less' : 'Read more testimonials'}
                      <Iconify
                        icon={'ic:round-arrow-right-alt'}
                        sx={{ ml: 1, width: 20, height: 20 }}
                      />
                    </Button>
                  </MotionInView>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={readMoreTestimonals ? 10 : 8}
            lg={readMoreTestimonals ? 8 : 6}
            sx={{
              right: { md: 24 },
              position: { md: 'absolute' },
            }}
          >
            <Grid container spacing={isDesktop ? 3 : 0} alignItems="center">
              <Grid item xs={12} md={6}>
                {TESTIMONIALS.slice(0, readMoreTestimonals ? 4 : 2).map((testimonial) => (
                  <MotionInView key={testimonial.name} variants={varFade().inUp}>
                    <TestimonialCard testimonial={testimonial} />
                  </MotionInView>
                ))}
              </Grid>

              <Grid item xs={12} md={6}>
                {TESTIMONIALS.slice(readMoreTestimonals ? 4 : 6, 7).map((testimonial) => (
                  <MotionInView key={testimonial.name} variants={varFade().inUp}>
                    <TestimonialCard testimonial={testimonial} />
                  </MotionInView>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

// function TestimonialLink() {
//   return (
//     <Button onClick={handleTestimonial} sx={{ display: 'flex', alignItems: 'center',  fontSize: 19  }}>
//       Read more testimonials
//       <Iconify icon={'ic:round-arrow-right-alt'} sx={{ ml: 1, width: 20, height: 20 }} />
//     </Button>
//   );
// }

type TestimonialCardProps = {
  testimonial: {
    name: string;
    rating: number;
    content: string;
    dateCreate: Date | string;
  };
};

function TestimonialCard({ testimonial }: TestimonialCardProps) {
  const theme = useTheme();
  const { name, rating, dateCreate, content } = testimonial;

  return (
    <Paper
      sx={{
        mt: 3,
        p: 3,
        color: 'common.white',
        ...cssStyles().bgBlur({
          color: theme.palette.common.white,
          opacity: 0.04,
        }),
      }}
    >
      <Typography sx={{ fontFamily: FONT_SECONDARY }} gutterBottom>
        {name}
      </Typography>
      <Typography gutterBottom component="p" sx={{ color: 'grey.500' }}>
        {dateCreate}
      </Typography>
      {/* <Rating value={rating} readOnly size="small" /> */}
      <Typography sx={{ mt: 1.5 }}>{content}</Typography>
    </Paper>
  );
}
