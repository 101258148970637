import { Button } from '@mui/material';
import { FONT_SECONDARY, FONT_TERTIARY } from 'src/theme/typography';

{
  /* <Button
size="large"
variant="contained"
target="_blank"
rel="noopener"
href="https://forms.gle/GH2jXFrituGxMAqE7"
sx={{

  fontFamily: FONT_TERTIARY,
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  boxShadow: (theme) => theme.customShadows.z8,
  color: (theme) => theme.palette.getContrastText(theme.palette.common.white),
  bgcolor: 'common.white',
  '&:hover': { bgcolor: 'grey.300' },
}}
>
Register Here
</Button> */
}

function ButtonRegister() {
  return (
    <Button
      size="large"
      variant="contained"
      href={'https://forms.gle/DwujX5XqpECHWRew9'}
      sx={{ textTransform: 'uppercase', fontFamily: FONT_TERTIARY }}
    >
      Register here
    </Button>
  );
}

export default ButtonRegister;
