// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Card,
  Link,
  Stack,
  Button,
  Divider,
  Container,
  Typography,
  Avatar,
} from '@mui/material';
// _mock_
import { _homePlans } from '../../_mock';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.neutral,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

// ----------------------------------------------------------------------

export default function HomeChairman() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container>
        <Box sx={{ mb: 2, textAlign: 'center' }}>
          <MotionInView variants={varFade().inUp}>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              Pastor Brian King (BEM KEC)
            </Typography>

            <Typography
              sx={{
                color: isLight ? 'text.secondary' : 'text.primary',
                textTransform: 'uppercase',
              }}
              variant="h3"
            >
              A word
            </Typography>

            <Typography variant="h3" sx={{textTransform: 'uppercase' }}>
              From the chairman
            </Typography>
          </MotionInView>
        </Box>

        <Grid container justifyContent="center" sx={{ mb: 4 }}>
          <Avatar sx={{ width: 90 , height: 90 }} src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515010/NextGen/Brian_King_tk79es.jpg" />
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <MotionInView variants={varFade().inUp}>
              <Card
                sx={{
                  p: 5,
                  boxShadow: (theme) => theme.customShadows.z24,
                  fontSize: 19
                }}
              >
                Groan. <br /> <br /> 1. to utter a deep moan indicative of pain, grief or annoyance{' '}
                <br /> 2. to make a harsh sound (as of creaking) under sudden or prolonged strain
                <br /> <br /> I saw a comic recently, in which a wife passes a note to her husband,
                saying: “Since you’re always asking, here’s a list of my various sighs and groans,
                and what they all mean!” That might raise a chuckle among us, but the truth is,
                there’s been plenty of groaning in the last few years, haven’t they? There are the
                groans of nature, inflicted upon us – seen most clearly in the emergence of the most
                famous virus in the world: Covid-19. Then there are the groans of human beings: the
                groans of those made sick by the virus, the groans of worry and anguish made by
                their loved ones, the groans made by healthcare workers everywhere as they pull yet
                another double shift, the groans of those whose livelihoods are affected by the
                pandemic, the groans of Christians who miss the fellowship of one another. <br />{' '}
                <br /> But God’s Word tells us these groans will not last forever. For our Saviour
                too groaned, as he hung on the cross. And he does so, to make sure one day we will
                be free from groaning. As the lyric in the song “Is there anything too hard for
                you?” puts it: “The darkest night will be your brightest victory!” And as Jesus dies
                and rises again, he ensures that one day, we will be singing a song of triumph, not
                groaning endlessly in defeat. Indeed, he tells us his Spirit will groan for us in
                the meantime, to keep us going to the end.
                <br /> <br />
                This year, KVBC NextGen continues as an online conference. And while no doubt some
                of you will groan at hearing that, we want to see this as an opportunity; an
                opportunity to bring NextGen into your homes all across Malaysia so that many will
                continue to be equipped to handle the Word rightly, to love the Author of that Word
                more, and to find fellowship with other like-minded believers. And we especially
                want to encourage everyone by turning specifically to the book of Haggai and the
                magnificent chapter of Romans 8, to see that one day, all the groanings we suffer
                through now will cease. For all God’s people are on a journey “From groaning to
                glory”. That will be the theme of our conference this year. <br /> <br /> So we hope
                to see you there! Next year, we hope to see everybody in person! But for now, we
                will gather in this way. If you are someone who has benefitted from NextGen in
                previous years, we’ll really appreciate it if you can get the word out and invite as
                many as you can who would find it similarly beneficial, so that the Malaysian church
                can be blessed.
                <br />
                <br />
                Warmly in Christ, <br /> Brian King  <br />Chair, KVBC NextGen
              </Card>
              {/* <PlanCard plan={plan} /> */}
            </MotionInView>
          </Grid>
        </Grid>

        {/* <MotionInView variants={varFade().in}>
          <Box sx={{ p: 5, mt: 10, textAlign: 'center' }}>
            <MotionInView variants={varFade().inDown}>
              <Typography variant="h3">Still have questions?</Typography>
            </MotionInView>

            <MotionInView variants={varFade().inDown}>
              <Typography sx={{ mt: 3, mb: 5, color: 'text.secondary' }}>
                Please describe your case to receive the most accurate advice.
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Button
                size="large"
                variant="contained"
                href="mailto:support@minimals.cc?subject=[Feedback] from Customer"
              >
                Contact us
              </Button>
            </MotionInView>
          </Box>
        </MotionInView> */}
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

// type PlanCardProps = {
//   plan: {
//     license: string;
//     commons: string[];
//     options: string[];
//     icons: string[];
//   };
// };

// function PlanCard({ plan }: PlanCardProps) {
//   const { license, commons, options, icons } = plan;

//   const standard = license === 'Standard';
//   const plus = license === 'Standard Plus';

//   return (
//     <Card
//       sx={{
//         p: 5,
//         boxShadow: 0,
//         ...(plus && {
//           boxShadow: (theme) => theme.customShadows.z24,
//         }),
//       }}
//     >
//       <Stack spacing={5}>
//         <div>
//           <Typography variant="overline" component="div" sx={{ mb: 2, color: 'text.disabled' }}>
//             LICENSE adsa
//           </Typography>
//           <Typography variant="h4">{license}</Typography>
//         </div>

//         {standard ? (
//           <Image src={icons[2]} sx={{ width: 40, height: 40 }} />
//         ) : (
//           <Stack direction="row" spacing={1}>
//             {icons.map((icon) => (
//               <Image key={icon} src={icon} sx={{ width: 40, height: 40 }} />
//             ))}
//           </Stack>
//         )}

//         <Stack spacing={2.5}>
//           {commons.map((option) => (
//             <Stack key={option} spacing={1.5} direction="row" alignItems="center">
//               <Iconify
//                 icon={'eva:checkmark-fill'}
//                 sx={{ color: 'primary.main', width: 20, height: 20 }}
//               />
//               <Typography variant="body2">{option}</Typography>
//             </Stack>
//           ))}

//           <Divider sx={{ borderStyle: 'dashed' }} />

//           {options.map((option, optionIndex) => {
//             const disabledLine =
//               (standard && optionIndex === 1) ||
//               (standard && optionIndex === 2) ||
//               (standard && optionIndex === 3) ||
//               (plus && optionIndex === 3);

//             return (
//               <Stack
//                 spacing={1.5}
//                 direction="row"
//                 alignItems="center"
//                 sx={{
//                   ...(disabledLine && { color: 'text.disabled' }),
//                 }}
//                 key={option}
//               >
//                 <Iconify
//                   icon={'eva:checkmark-fill'}
//                   sx={{
//                     width: 20,
//                     height: 20,
//                     color: 'primary.main',
//                     ...(disabledLine && { color: 'text.disabled' }),
//                   }}
//                 />
//                 <Typography variant="body2">{option}</Typography>
//               </Stack>
//             );
//           })}
//         </Stack>

//         <Stack direction="row" justifyContent="flex-end">
//           <Link
//             color="text.secondary"
//             underline="always"
//             target="_blank"
//             rel="noopener"
//             href="https://material-ui.com/store/license/#i-standard-license"
//             sx={{ typography: 'body2', display: 'flex', alignItems: 'center' }}
//           >
//             Learn more <Iconify icon={'eva:chevron-right-fill'} width={20} height={20} />
//           </Link>
//         </Stack>

//         <Button
//           size="large"
//           fullWidth
//           variant={plus ? 'contained' : 'outlined'}
//           target="_blank"
//           rel="noopener"
//           href="https://material-ui.com/store/items/minimal-dashboard/"
//         >
//           Choose Plan
//         </Button>
//       </Stack>
//     </Card>
//   );
// }
