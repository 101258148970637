import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// components
import { MotionContainer, TextAnimate, varFade } from '../../components/animate';
import useResponsive from 'src/hooks/useResponsive';
import { FONT_TITLE } from 'src/theme/typography';

// ----------------------------------------------------------------------
interface RootStyleProps {
  isDesktop?: boolean;
}

const RootStyle = styled('div')<RootStyleProps>(({ theme, isDesktop }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: isDesktop ? 'center calc(50% - 40px)' : 'center',
  backgroundImage:
    'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515243/NextGen/19477365_10154500875162470_4734925723676249589_o_hyis2z.jpg)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    // backgroundImage: 'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1680440101/NextGen/overlay_static2_kace1d.png)',
    // opacity: 0.5
  }}
));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function SpeakerHero() {
  const isDesktop = useResponsive('up', 'md');
  return (
    <RootStyle isDesktop={isDesktop}>
      <Container component={MotionContainer} sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          <Box sx={{ display: 'block', color: 'common.white' }}>
            <TextAnimate text="Meet" sx={{ mr: 2, fontFamily: FONT_TITLE }} variants={varFade().inRight} />
            <TextAnimate text="Our" sx={{ mr: 2, fontFamily: FONT_TITLE }} variants={varFade().inRight} />
            <TextAnimate text="Speakers" sx={{ fontFamily: FONT_TITLE }}variants={varFade().inRight} />
          </Box>

          {isDesktop && (
            <m.div variants={varFade().inRight}>
              <Typography
                variant="h4"
                sx={{
                  mt: 5,
                  color: 'common.white',
                  fontWeight: 'fontWeightMedium',
                }}
              >
                {' '}
                {/* Let's work together and
              <br /> make awesome site easily */}
              </Typography>
            </m.div>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
