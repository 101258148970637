// @mui
import { Typography, Box, Paper } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'The conference fee is fully transferable but non-refundable.',
    href: '#',
  },
  {
    label:
      'Please email us at kvbcnextgen@gmail.com if there are changes in your conference availability.',
    href: '#',
  },
];

// ----------------------------------------------------------------------

export default function ConferenceDetailsBulletPoint() {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      <Box
        sx={{
          mb: 3,
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 1fr)',
          },
        }}
      >
        {CATEGORIES.map((category) => (
          <MotionInView key={category.label} variants={varFade().in}>
            <CategoryCard category={category} />
          </MotionInView>
        ))}
      </Box>
      <Paper
        variant="outlined"
        sx={{
          px: 2,
          height: isDesktop ? 150 : 250,
          borderRadius: 2,
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          boxShadow: (theme) => theme.customShadows.z8,
          '&:hover': {
            boxShadow: (theme) => theme.customShadows.z24,
          },
        }}
      >
        <Typography sx={{ fontSize: 19 }}>
          Registration is only confirmed with your payment slip uploaded. Please proceed to make
          your payment to complete registration and then upload your payment slip in the
          registration form.
        </Typography>
      </Paper>
    </>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label } = category;

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 2,
        height: 150,
        borderRadius: 2,
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: (theme) => theme.customShadows.z8,
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24,
        },
      }}
    >
      <Typography sx={{ fontSize: 19 }}>{label}</Typography>
    </Paper>
  );
}
