// @mui
import { Typography, Box, Paper, Container, Grid } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'Brian King',
    icon: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515010/NextGen/Brian_King_tk79es.jpg',
    href: '#',
    no: '1',
    description: (
      <span>
        Brian is married to Chin Yin and they have 3 active children. He has been part of the KVBC
        NextGen team since 2015, and has served as its chairman since 2020. He also currently serves
        as the pastor of BEM Kuching Evangelical Church, which is also the church he grew up in!
        <br /> <br />
        From young, Brian followed his mum to church before putting his trust in Jesus personally as
        a teenager. After studying in Oxford and London, Brian did a church apprenticeship in the UK
        and served as a pastoral worker at a church in KL before getting his M.Div at Sydney
        Missionary & Bible College. In his spare time, Brian enjoys reading (though he admits his
        attention span is getting worse nowadays), playing badminton, following Manchester United
        and the NBA, and lately, some K-drama.
        <br /> <br />
        Brian will be preaching on Titus. He is convinced this is one of the more underrated letters
        of Paul! In short space, Paul wants to grow our conviction that this gospel can transform
        even the most unlikely of people so that they in turn will be zealous for good works. This
        is a conviction desperately needed by Christians of every generation, and he hopes it will
        be one all who come to NextGen 2024 will catch.
      </span>
    ),
  },
  {
    label: 'Chris Wylie',
    icon: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1706703248/NextGen/2024/Chris_Wylie_bwbhet.jpg',
    href: '#',
    no: '2',
    description: (
      <span>
        Chris is from the UK but has been living in Malaysia for over 20 years. He and his wife
        Cindy have 4 children - the oldest has just finished SPM and the youngest is still in
        diapers! Chris serves full time in Faith Evangelical Free Church in OUG, KL, which is the
        same church he attended long before becoming the pastor.
        <br />
        <br />
        In the UK, Chris grew up going to church. But it wasn't until he was studying at the
        University of Sheffield that he was convicted of his sin and convinced about the truth of
        the gospel. After graduating, he stayed on in Sheffield for two more years as an intern with
        Friends International, reaching out to international students. He then came to Malaysia to
        teach English to speakers of other languages (TESOL) and even worked with Astro on "Oh My
        English!". It was while working and studying part time for a Masters in Christian Studies
        with STM that he discerned God calling him into full-time ministry.
        <br />
        <br />
        Chris will be preaching from the book of Ezekiel, which was the first book he studied at STM
        and has held a special place in his heart ever since. Ezekiel is weird and wonderful,
        gob-smacking and glorious. Not many Christians read it and not many churches preach from it
        - and we are all the poorer for it. It is Chris's hope that everyone at NextGen 2024 will be
        granted a fresh glimpse of the glory of God in Ezekiel that will make us even more grateful
        for the good news about Jesus. <br />
        <br />
        {/* Vernon serves as the Pastor of the 4:30PM Congregation at Zion Bishan Bible-Presbyterian
        Church in Singapore. He is especially passionate about equipping Christians to disciple
        others through God’s Word. He himself has benefited from the same kind of equipping through
        various ministries in Perth, Singapore, Sydney and London – including Moore Theological
        College (Sydney) and St Helen’s Bishopsgate (London). He is the husband of one wife, Ruth,
        and is the father of Alyssa, Thaddeus & Ebenezzer (who went Home to the Lord in October
        2022).
        <br /> <br />
        Besides talking about the Good News of Jesus, his passions include good stories in any form,
        serving in the Singapore Army and jumping out of airplanes. Both Vernon and Ruth’s mothers
        were born in Malaysia, so between them there is a full heart for the Gospel to go out in
        Malaysia through NextGen this year.
        <br /> <br />
        Vernon will be preaching on John Chapters 13-17: Jesus’ crucial instructions for the
        disciples on His last night with them in this world – instructions that all who desire to
        follow Him today must know too! */}
      </span>
    ),
  },
];

// ----------------------------------------------------------------------

export default function SpeakerList() {
  return (
    <Box
      sx={{
        my: 1,
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
        },
      }}
    >
      {CATEGORIES.map((category) => (
        <MotionInView key={category.label} variants={varFade().in}>
          <CategoryCard category={category} />
        </MotionInView>
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
    icon: string;
    description: any;
    no: string;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label, icon, description, no } = category;

  return (
    <Paper sx={{ borderRadius: 2, borderColor: 'white', textAlign: 'center', px: 3 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4} md={6}>
          <Image
            src={icon}
            ratio="3/4"
            sx={{
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" sx={{ fontSize: 29, my: 2, fontFamily: FONT_SECONDARY }}>
        {label}
      </Typography>
      <Typography sx={{ mb: 1, fontSize: 19, textAlign: 'left' }}>{description}</Typography>
    </Paper>
  );
}
