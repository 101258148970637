import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
// components
import { MotionContainer, TextAnimate, varFade } from '../../components/animate';
import { FONT_TITLE } from 'src/theme/typography';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------
interface RootStyleProps {
  isDesktop?: boolean;
}

const RootStyle = styled('div')<RootStyleProps>(({ theme, isDesktop }) => ({
  position: 'relative',
  // https://stackoverflow.com/questions/4779577/background-images-how-to-fill-whole-div-if-image-is-small-and-vice-versa
  backgroundSize: isDesktop ? '100% 195%' : '100%',
  backgroundPosition: isDesktop ? 'center calc(50% - 20px)' : 'center',
  backgroundImage:
    'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1706346074/NextGen/2024/MG_6425-min_mxkhmp.webp)',
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    height: 560,
    padding: 0,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    // backgroundImage: 'url(https://res.cloudinary.com/dscrdg0ou/image/upload/v1680440101/NextGen/overlay_static2_kace1d.png)',
    // opacity: 0.5
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    position: 'absolute',
    bottom: theme.spacing(10),
  },
}));

// ----------------------------------------------------------------------

export default function AboutHero() {
const isDesktop = useResponsive('up', 'lg');

  return (
    <RootStyle isDesktop={isDesktop}>
      <Container component={MotionContainer} sx={{ position: 'relative', height: '100%' }}>
        <ContentStyle>
          {/* <TextAnimate text="Who" sx={{ color: 'primary.main' }} variants={varFade().inRight} /> */}

          <Box sx={{ display: 'inline-flex', color: 'common.white' }}>
            <TextAnimate text="About" sx={{ mr: 2, fontFamily: FONT_TITLE }} />
            {/* <TextAnimate text="" /> */}
          </Box>

          <m.div variants={varFade().inUp}>
            <Typography
              variant="h4"
              sx={{
                mt: 5,
                color: 'common.white',
                fontWeight: 'fontWeightMedium',
              }}
            >
              {' '}
              {/* Let's work together and
              <br /> make awesome site easily */}
            </Typography>
          </m.div>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
