// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Button,
  Container,
  Typography,
  LinearProgress,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { fPercent } from '../../utils/formatNumber';
// _mock_
import { _skills } from '../../_mock';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { MotionInView, varFade } from '../../components/animate';
import { AboutIfYouAre, AboutMinistry, AboutTaught } from '.';
import './styles.css';
import { FONT_SECONDARY } from 'src/theme/typography';
// ----------------------------------------------------------------------

const StyledListItem = styled(ListItem)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const ListItemTextPrimary = styled(ListItemText)({});

const ListItemTextSecondary = styled(ListItemText)({});

const RootStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingTop: theme.spacing(20),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
  },
}));

// const Icon = styled(School)({
//   fontSize: '3rem',
//   color: '#fff',
// });
// ----------------------------------------------------------------------

export default function AboutWhat() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const isLight = theme.palette.mode === 'light';
  const shadow = `-40px 40px 80px ${alpha(
    isLight ? theme.palette.grey[500] : theme.palette.common.black,
    0.48
  )}`;

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={6}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680756881/NextGen/What_is_KVBC_NextGen_bdee3s.jpg"
                    ratio="3/4"
                    sx={{
                      borderRadius: 2,
                      boxShadow: shadow,
                    }}
                  />
                </MotionInView>
              </Grid>
              <Grid item xs={6}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680756880/NextGen/What_is_KVBC_NextGen_1_o3jose.jpg"
                    ratio="1/1"
                    sx={{ borderRadius: 2 }}
                  />
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <MotionInView variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
                What is KVBC NextGen?
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                  fontSize: 19,
                }}
                // variant="h6"
              >
                A ministry of the KVBC Trust, KVBC NextGen is a 4-day training conference for
                leaders who are involved in children, youth and campus ministries. <br />
                <br /> NextGen aims to raise up, equip and, encourage the next generation of
                Christian leaders to grow in godliness, to teach the Bible faithfully in their
                ministries, and to proclaim Jesus for the glory of God across Malaysia!
              </Typography>
            </MotionInView>

            {/* <Box sx={{ my: 5 }}>
              {_skills.map((progress) => (
                <MotionInView key={progress.label} variants={varFade().inUp}>
                  <ProgressItem progress={progress} />
                </MotionInView>
              ))}
            </Box> */}

            {/* <MotionInView variants={varFade().inUp}>
              <Button
                variant="outlined"
                color="inherit"
                size="large"
                endIcon={<Iconify icon={'ic:round-arrow-right-alt'} width={24} height={24} />}
              >
                Check out our work
              </Button>
            </MotionInView> */}
          </Grid>
        </Grid>

        <Container sx={{ mt: 15, mb: 10 }}>
          <MotionInView variants={varFade().inUp}>
            <Typography variant="h3" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
              At the conference, delegates will be taught:
            </Typography>
          </MotionInView>
          <AboutTaught />
          {!isDesktop && (
            <>
            <Grid item sx={{ mt: 3, mb: 5 }} xs={12} md={6} lg={6}>
                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={6}>
                      <MotionInView variants={varFade().inUp}>
                        <Image
                          src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1706539910/NextGen/2024/MG_3919-min_zxjafu.webp"
                          ratio="1/1"
                          sx={{
                            borderRadius: 2,
                            boxShadow: shadow,
                          }}
                        />
                      </MotionInView>
                    </Grid>
                    <Grid item xs={6}>
                      <MotionInView variants={varFade().inUp}>
                        <Image
                          src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680756881/NextGen/If_you_are..._1_kahzhu.jpg"
                          ratio="9/16"
                          sx={{ borderRadius: 2 }}
                        />
                      </MotionInView>
                    </Grid>
                  </Grid>
                </Grid>
              
              <MotionInView variants={varFade().inUp}>
                <Typography variant="h3" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
                  If you are:
                </Typography>
              </MotionInView>
              <AboutIfYouAre />
              <MotionInView variants={varFade().inUp}>
                <Typography variant="h3" sx={{ fontFamily: FONT_SECONDARY }}>
                  …then NextGen is the conference for you!
                </Typography>
              </MotionInView>
            </>
          )}
          {isDesktop && (
            <Grid container spacing={3}>
              <Grid md={6}>
                <MotionInView variants={varFade().inUp}>
                  <Typography variant="h3" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
                    If you are:
                  </Typography>
                </MotionInView>
                {/* {isDesktop && <AboutMinistry />} */}

                <Box>
                  <List>
                    <StyledListItem>
                      <Typography sx={{ fontSize: 25 }}>• Aged 18 to 35 years old</Typography>
                    </StyledListItem>
                    <StyledListItem>
                      <Typography sx={{ fontSize: 25 }}>• Serving at a local church</Typography>
                    </StyledListItem>
                    <StyledListItem>
                      <ListItemText
                        primary={
                          <ListItemTextPrimary>
                            <Typography sx={{ fontSize: 25 }}>
                              • Serving in one of the following areas:
                            </Typography>
                          </ListItemTextPrimary>
                        }
                        secondary={
                          <ListItemTextSecondary>
                            <Typography sx={{ fontSize: 21 }}>
                              <ul>
                                <li>Children’s ministry</li>
                                <li>Youth/Teens’ ministry</li>
                                <li>University College ministry</li>
                                <li>Christian Fellowship / Union</li>
                                <li>Cell Group / Bible Study Group</li>
                                <li>Any Christian organization</li>
                              </ul>
                            </Typography>
                          </ListItemTextSecondary>
                        }
                      />
                    </StyledListItem>
                  </List>
                </Box>

                <MotionInView variants={varFade().inUp}>
                  <Typography variant="h4" sx={{ fontFamily: FONT_SECONDARY }}>
                    …then NextGen is the conference for you!
                  </Typography>
                </MotionInView>
              </Grid>

              
                <Grid item sx={{ mt: 3 }} xs={12} md={6} lg={6}>
                  <Grid container spacing={3} alignItems="flex-end">
                    <Grid item xs={6}>
                      <MotionInView variants={varFade().inUp}>
                        <Image
                          // src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1706539910/NextGen/2024/MG_3919-min_zxjafu.webp"
                          src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680757505/NextGen/If_you_are..._ecuk9u.jpg"
                          ratio="1/1"
                          sx={{
                            borderRadius: 2,
                            boxShadow: shadow,
                          }}
                        />
                      </MotionInView>
                    </Grid>
                    <Grid item xs={6}>
                      <MotionInView variants={varFade().inUp}>
                        <Image
                          // src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680756881/NextGen/If_you_are..._1_kahzhu.jpg"
                          src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1706540534/NextGen/2024/MG_4626-min_cxu7xu.webp"
                          ratio="9/16"
                          sx={{ borderRadius: 2 }}
                        />
                      </MotionInView>
                    </Grid>
                  </Grid>
                </Grid>
              
            </Grid>
          )}
        </Container>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

type ProgressItemProps = {
  progress: {
    label: string;
    value: number;
  };
};

function ProgressItem({ progress }: ProgressItemProps) {
  const { label, value } = progress;
  return (
    <Box sx={{ mt: 3 }}>
      <Box sx={{ mb: 1.5, display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle2">{label}&nbsp;-&nbsp;</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {fPercent(value)}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          '& .MuiLinearProgress-bar': { bgcolor: 'grey.700' },
          '&.MuiLinearProgress-determinate': { bgcolor: 'divider' },
        }}
      />
    </Box>
  );
}
