// @mui
import { Box, Container, Typography, Grid, Paper } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { styled } from '@mui/material/styles';
import AboutStrandGroups from './AboutStrandGroups';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

export default function AboutVision() {
  const RootStyle = styled('div')(({ theme }) => ({
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
  }));

  return (
    <RootStyle>
      <Container>
        <Paper 
        sx={{ // backgroundImage: 'url(https://images.unsplash.com/photo-1629194891750-cd24c0b9596f?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
         p: {xs: 5, md: 10} }}>
        <Container sx={{ pb: 10, textAlign: 'center' }}>
          <MotionInView variants={varFade().inDown}>
            <Typography
              component="p"
              variant="overline"
              sx={{ mb: 2, color: 'text.secondary', fontSize: 15 }}
            >
              2024{' '}
            </Typography>
          </MotionInView>

          <MotionInView variants={varFade().inUp}>
            <Typography variant="h2" sx={{ mb: 3, fontFamily: FONT_SECONDARY }}>
              What can you expect?
            </Typography>
          </MotionInView>
        </Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515244/NextGen/Workshop-2_wrghky.jpg"
                    ratio="1/1"
                    sx={{
                      borderRadius: 2,
                    }}
                  />
                </MotionInView>
              </Grid>
              {/* <Grid item xs={6}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    src="https://minimal-assets-api.vercel.app/assets/images/about/what-2.jpg"
                    ratio="1/1"
                    sx={{ borderRadius: 2 }}
                  />
                </MotionInView>
              </Grid> */}
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <MotionInView variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3 }}>
                The Preaching of the Word
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                  fontSize: 19,
                  fontWeight: '500'
                }}
                // variant="h6"
              >
                There will be daily morning and evening sessions, whereby all delegates meet
                together to sing and listen to the word of God being preached in an expository
                manner. During these sessions, delegates will be able to see first-hand the
                application of some of the things they’ve learnt during the week. NextGen preachers
                from the past years have come from various different churches across Malaysia and
                Singapore, all of whom are active and seasoned Bible teachers!
              </Typography>
            </MotionInView>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 10, mb: 5 }}>
          <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    // src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680358853/NextGen/strand_groups_gp5cnj.jpg"
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1706539670/NextGen/2024/strand_xpyz9t.webp"
                    ratio="4/3"
                    sx={{
                      borderRadius: 2,
                    }}
                  />
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <MotionInView variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3 }}>
                Strand Groups
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                  fontSize: 19,
                  fontWeight: '500'
                }}
                // variant="h6"
              >
                Training groups are at the heart of KVBC NextGen. Delegates will be placed into
                “strand groups” where you will be taught how to read, understand and, faithfully
                teach the Bible, specific to your ministry. Strand groups will also be given free
                time throughout the conference so that delegates can get to know each other in an
                informal and fun setting!
              </Typography>
            </MotionInView>
          </Grid>
        </Grid>

     <AboutStrandGroups />

        <Grid container spacing={3} sx={{ mt: 5 }}>
          <Grid item xs={12} md={6} lg={7} sx={{ pr: { md: 7 } }}>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={12}>
                <MotionInView variants={varFade().inUp}>
                  <Image
                    // src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678515244/NextGen/Workshop_wl7hxf.jpg"
                    src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1706539263/NextGen/2024/workshop_gga3ld.webp"
                    ratio="4/3"
                    sx={{
                      borderRadius: 2,
                    }}
                  />
                </MotionInView>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6} lg={5}>
            <MotionInView variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3 }}>
                Workshops
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Typography
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                  fontSize: 19,
                  fontWeight: '500'
                }}
                // variant="h6"
              >
                There will be electives which delegates will be able to select from, run through
                workshops. These workshops will provide delegates with tailored guidance in various
                practical aspects of ministry. Some of the workshops this year include: <br/> • The
                Personal Life of A Leader <br/>• One-to-One Ministry<br/>• Leading Small Groups
                <br/>• The Gospel and Same-Sex Attraction <br/>• Disputable matters
              </Typography>
            </MotionInView>
          </Grid>
        </Grid>
        </Paper>
      </Container>
    </RootStyle>
  );
}
