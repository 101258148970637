// ----------------------------------------------------------------------

export const role = [
  'UX Designer',
  'Full Stack Designer',
  'Backend Developer',
  'UX Designer',
  'UX Designer',
  'Project Manager',
  'Leader',
  'Backend Developer',
  'Project Manager',
  'UI Designer',
  'UI/UX Designer',
  'UI/UX Designer',
  'UI Designer',
  'Backend Developer',
  'Backend Developer',
  'Front End Developer',
  'Backend Developer',
  'Full Stack Designer',
  'Full Stack Developer',
  'Backend Developer',
  'UX Designer',
  'UI Designer',
  'Project Manager',
  'UI/UX Designer',
  'UI Designer',
  'Project Manager',
  'Full Stack Developer',
  'Hr Manager',
  'Hr Manager',
  'UI/UX Designer',
  'Project Manager',
  'Full Stack Designer',
  'UI Designer',
  'Leader',
  'Front End Developer',
  'UI/UX Designer',
  'Project Manager',
  'UI/UX Designer',
  'UI Designer',
  'Full Stack Designer'
];

export const photo = [
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780888/NextGen/carousel/2018-min_tn9osi.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680754417/NextGen/instagramcrop_xo6jii.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780888/NextGen/carousel/13613605_1735272160054973_5086303498245992931_o-min_ckjwdy.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780887/NextGen/carousel/13641114_1735057580076431_147421239669288242_o-min_mlxva5.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780888/NextGen/carousel/13668917_1735268876721968_6602492541520916141_o-min_p4afzf.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/13679890_1735057993409723_1746125704076274744_o-min_ubkbd6.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/13680076_1735057296743126_3467117677037424818_o-min_xatjig.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/62648848_2293694074212776_1942365978562134016_n-min_becdiz.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/64325121_2293695430879307_7378187007693946880_n-min_eg6wbl.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/63137968_2293691380879712_8975380569349160960_n-min_e4qxju.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780888/NextGen/carousel/13669425_1735275006721355_8468880269883218193_o-min_twj6a8.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780889/NextGen/carousel/Copy_of__MG_2645-min_rdkhl7.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780890/NextGen/carousel/Copy_of__MG_2660-min_fkfdcu.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780890/NextGen/carousel/Q_A-min_vxiost.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780890/NextGen/carousel/St_Mary_s-min_jfu1qs.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780890/NextGen/carousel/Strand_Group-min_ggq4pl.jpg',
  'https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780890/NextGen/carousel/Strand_3_Graduates-min_fpzwtk.jpg',
  'UX Designer',
  '',
  '',
  '',
  '',
  '',
  'Full Stack Developer',
  'Hr Manager',
  'Hr Manager',
  '',
  '',
  'Full Stack Designer',
  'UI Designer',
  'Leader',
  'Front End Developer',
  '',
  '',
  '',
  'UI Designer',
  'Full Stack Designer'
];

