import { Accordion, Typography, AccordionSummary, AccordionDetails, Grid } from '@mui/material';
// _mock_
import { _sg } from '../../_mock';
// components
import Iconify from '../../components/Iconify';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

export default function AboutStrandGroups() {
  return (
    <>
      {_sg.map((accordion, index) => (
        <Accordion key={accordion.id} defaultExpanded sx={{ mt: 5 }}>
          <AccordionSummary
            expandIcon={<Iconify icon={'eva:arrow-ios-downward-fill'} width={20} height={20} />}
          >
            <Grid
              container
              justifyContent={{ xs: 'center', md: 'space-between' }}
              sx={{ textAlign: { xs: 'center', md: 'left' } }}
            >
              <Grid item xs={12}>
                <Typography variant="h3" sx={{ fontFamily: FONT_SECONDARY }}>
                  {accordion.heading}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h3">
                  {accordion.subtitle}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {' '}
              <span style={{ whiteSpace: 'pre-line', fontSize: 19 }}>{accordion.detail}</span>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
