import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
//
import Image from 'src/components/Image';
import { MaintenanceIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Maintenance() {
  return (
    <Page title="Maintenance" sx={{ height: 1 }}>
      <RootStyle>
        <Container sx={{ textAlign: 'center' }}>
          <Typography variant="h3" paragraph>
            Website currently under maintenance
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>We will be back soon!</Typography>
          <Grid container sx={{ justifyContent: 'center', mt: 10 }}>
            <Image
              src={
                'https://img.freepik.com/free-vector/maintenance-concept-illustration_114360-391.jpg?w=1380&t=st=1678190042~exp=1678190642~hmac=bc8662f1a6893075c88e71847938738db96c7e0b98371dbd8fd40e6ce11b701c'
              }
              disabledEffect
              sx={{ borderRadius: 1, mb: 1, width: 344, height: 254 }}
            />
          </Grid>
          {/* <MaintenanceIllustration sx={{ my: 10, height: 240 }} /> */}
        </Container>
      </RootStyle>
    </Page>
  );
}
