import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  const logo = (
    <Box sx={{ width: 120, height: 40, ...sx }}>
      <img src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678517374/NextGen/KVBC-NextGen-Logo-2014_mtsruu.png" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
