// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Grid, Container, Button, Typography } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import useResponsive from '../../hooks/useResponsive';
import { FONT_SECONDARY } from 'src/theme/typography';
const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0,
  },
}));
// ----------------------------------------------------------------------

// const CARDS = [
//   {
//     // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_design.svg',
//     title: 'Why NextGen?',
//     description:
//       'Ministry is challenging! How do you read the bible? How do you handle scripture faithfully and teach it to others? We are often weighed down in running the ministry, without even having the time to think of our own growth and training.',
//   },
//   {
//     // icon: 'https://minimal-assets-api.vercel.app/assets/icons/ic_code.svg',
//     title: 'What is NextGen?',
//     description:
//       'KVBC NextGen is an annual conference that seeks to better equip you to faithfully handle the Bible well & learn to teach it to others in your ministries!',
//   },
//   {
//     // icon: '/logo/logo_single.svg',
//     title: 'How is NextGen like?',
//     description:
//       'Join us for a 4-day training conference that seeks to equip Christian leaders in children, youth and campus ministries throughout Malaysia; to grow in godliness, in faithfully handling and teaching the Bible to others, and in bringing up godly leaders of the next generation.',
//   },
// ];

// const shadowIcon = (color: string) => `drop-shadow(2px 2px 2px ${alpha(color, 0.48)})`;

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
  },
}));

// const CardStyle = styled(Card)(({ theme }) => {
//   const shadowCard = (opacity: number) =>
//     theme.palette.mode === 'light'
//       ? alpha(theme.palette.grey[500], opacity)
//       : alpha(theme.palette.common.black, opacity);

//   return {
//     border: 0,
//     maxWidth: 380,
//     minHeight: 400,
//     margin: 'auto',
//     textAlign: 'center',
//     padding: theme.spacing(10, 5, 0),
//     boxShadow: theme.customShadows.z12,
//     '&.cardCenter, &.cardLeft, &.cardRight': {
//       [theme.breakpoints.up('md')]: {
//         backgroundColor: theme.palette.background.paper,
//         boxShadow: `-40px 40px 80px 0 ${shadowCard(0.4)}`,
//         '&:before': {
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           zIndex: -1,
//           content: "''",
//           margin: 'auto',
//           position: 'absolute',
//           width: 'calc(100% - 40px)',
//           height: 'calc(100% - 40px)',
//           borderRadius: Number(theme.shape.borderRadius) * 2,
//           backgroundColor: theme.palette.background.paper,
//           boxShadow: `-20px 20px 40px 0 ${shadowCard(0.12)}`,
//         },
//       },
//     },
//   };
// });

// ----------------------------------------------------------------------

export default function HomeMinimal() {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useResponsive('up', 'md');

  return (
    <RootStyle>
      <Container>
        <Box
          sx={{
            textAlign: 'center',
            mb: { xs: 1, md: 5 },
          }}
        >
          {/* <MotionInView variants={varFade().inUp}>
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }}>
              KVBC NEXTGEN
            </Typography>
          </MotionInView> */}
          {/* <Typography
            variant="h2"
            sx={{ textTransform: 'uppercase', fontFamily: FONT_SECONDARY, pl: { md: 7 }, pr: { md: 3 } }}
          >
            Train to Teach
          </Typography> */}
        </Box>

        {/* <Box
          sx={{
            display: 'grid',
            gap: { xs: 5, lg: 10 },
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' },
          }}
        >
          {CARDS.map((card, index) => (
            <MotionInView variants={varFade().inUp} key={card.title}>
              <CardStyle
                className={
                  (index === 0 && 'cardLeft') ||
                  (index === 1 && 'cardCenter') ||
                  (index === 2 && 'cardRight') ||
                  ''
                }
              >
                <Typography variant="h5" paragraph>
                  {card.title}
                </Typography>
                <Typography sx={{ color: isLight ? 'text.secondary' : 'common.white' }}>
                  {card.description}
                </Typography>
              </CardStyle>
            </MotionInView>
          ))}
        </Box> */}

        {/* <Box
          sx={{
            textAlign: 'center',
            mt: { xs: 10, md: 10 },
            mb: { xs: 10, md: 5 },
          }}
        >
          <MotionInView variants={varFade().inDown}>
            <Typography variant="h3" sx={{ textTransform: 'uppercase' }}>
              KVBC NEXTGEN 2023
            </Typography>
          </MotionInView>
        </Box> */}

        {/* <Grid container justifyContent="center" sx={{ mt: 3, textAlign: 'center' }}>
          <MotionInView variants={varFade().inUp}>
            <Typography
              sx={{
                mb: 5,
                color: isLight ? 'text.secondary' : 'common.white',
              }}
            >
              KVBC NextGen 2023 will be in person! the DNA of NextGen remains the same, to “Train To
              Teach”
            </Typography>
            <Grid container justifyContent="center">
              <Button
                size="large"
                color="primary"
                variant="outlined"
                // component={RouterLink}
                // to={PATH_PAGE.components}
              >
                Register here
              </Button>
            </Grid>
          </MotionInView>
        </Grid> */}

        <Grid container justifyContent="center">
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentStyle>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                alignItems="center"
                sx={{ mt: { xs: 5, md: -5 }, ml: { xs: 6, md: 0 } }}
              >
                <Grid>
                  {/* <Box sx={{ width: 240, height: 90 }}>
                    <img src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1678517374/NextGen/KVBC-NextGen-Logo-2014_mtsruu.png" />
                  </Box> */}

                  <Grid item xs={10}>
                    <Image
                      src="https://res.cloudinary.com/dscrdg0ou/image/upload/v1680780888/NextGen/carousel/2017-min_upfwo9.jpg"
                      ratio="4/3"
                      sx={{
                        borderRadius: 2,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </ContentStyle>
          </Grid>
          <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'top', mt: -6 }}>
            <ContentStyle>
              <Typography
                variant="h2"
                sx={{ textTransform: 'uppercase', fontFamily: FONT_SECONDARY, mb: 3 }}
              >
                Train to Teach
              </Typography>

              <Typography
                sx={{
                  color: isLight ? 'text.primary' : 'common.white',
                  fontSize: 19,
                }}
              >
                Ministry is challenging! How do you read the bible? How do you handle scripture
                faithfully and teach it to others? We are often weighed down in running the
                ministry, without even having the time to think of our own growth and training. What
                if you could be better equipped to faithfully handle the Bible well & learn to teach
                it to others in your ministries?
                <br /> <br />
                KVBC NextGen Conference seeks to help you do just that! If you’re between the ages
                of 18-35, in Children / Youth / University College / Christian Fellowship / Young
                Adult ministry, please do join us!
              </Typography>
            </ContentStyle>
          </Grid>

          {/* <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'top' }}>
            <ContentStyle>
              <MotionInView variants={varFade().inUp}>
                <Typography variant="h3" sx={{ mb: 3, textTransform: 'uppercase' }}>
                  KVBC Nextgen 2023
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Typography
                  sx={{
                    mb: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  KVBC NextGen 2023 will be in person! the DNA of NextGen remains the same, to
                  “Train To Teach”
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inUp}>
                <Button
                  size="large"
                  color="primary"
                  variant="outlined"
                  // component={RouterLink}
                  // to={PATH_PAGE.components}
                >
                  Register here
                </Button>
              </MotionInView>
            </ContentStyle>
          </Grid> */}
        </Grid>
      </Container>
    </RootStyle>
  );
}
