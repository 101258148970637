import { useRef } from 'react';
import Slider from 'react-slick';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Card, Button, Container, Typography, Grid } from '@mui/material';
// _mock_
import { _carouselsMembers } from '../../_mock';
// components
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { CarouselArrows } from '../../components/carousel';
import SocialsButton from '../../components/SocialsButton';
import { MotionInView, varFade } from '../../components/animate';

// ----------------------------------------------------------------------

export default function AboutTeam() {
  const carouselRef = useRef<Slider>(null);
  const theme = useTheme();

  const settings = {
    arrows: false,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: '0px',
    rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        breakpoint: 1279,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 959,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 600,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const handlePrevious = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Container sx={{ pt: 1, pb: 5, textAlign: 'center' }}>
      {/* <MotionInView variants={varFade().inDown}>
        <Typography component="p" variant="overline" sx={{ mb: 2, color: 'text.secondary' }}>
          Dream team
        </Typography>
      </MotionInView> */}

       {/*<MotionInView variants={varFade().inUp}>
        <Typography variant="h2" sx={{ mb: 3, textTransform: 'uppercase', textAlign: { xs: 'left', md: 'center'} }}>
          Strand Groups
        </Typography>
      </MotionInView>
      <Grid container >
        <Grid item md={6} xs={12} sx={{ mb:{ xs: 3 } }}>
          <Typography
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? 'text.primary' : 'common.white'),
              // fontSize: 19,
              textAlign: { xs: 'left', md: 'right' },
              mr: { md: 5 },
              textTransform: 'uppercase',
            }}
            variant="h3"
          >
            Strand 1 <br />
            New Testament Exegesis
            <hr />
          </Typography>
        </Grid>
        <Grid item md={6}>
          <MotionInView variants={varFade().inUp}>
            <Typography
              sx={{
                mx: 'auto',
                maxWidth: 630,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                fontSize: 19,
                textAlign: 'left',
              }}
            >
              First time delegates will be automatically placed in this Strand. Here, you will be
              introduced to a model, which will help you understand the importance of considering
              the content and context of a passage, to arrive at the “Big Idea”, which will then
              shape the application of the passage. <br /> <br />
              As part of the training process, you will have the opportunity to write a Bible study
              on a selected passage New Testament. On the last day of NextGen, you will have the
              opportunity to present your study to the rest of your strand group.
            </Typography>
          </MotionInView>
        </Grid>
      </Grid>

      <Grid container  sx={{ mt: 10 }}>
        <Grid item md={6} xs={12} sx={{ mb:{ xs: 3 } }}>
          <Typography
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? 'text.primary' : 'common.white'),
              // fontSize: 19,
              textAlign: { xs: 'left', md: 'right' },
              mr: { md: 5 },
              textTransform: 'uppercase',
            }}
            variant="h3"
          >
            Strand 2 <br />
            Old Testament Exegesis
            <hr />
          </Typography>
        </Grid>
        <Grid item md={6}>
          <MotionInView variants={varFade().inUp}>
            <Typography
              sx={{
                mx: 'auto',
                maxWidth: 630,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                fontSize: 19,
                textAlign: 'left',
              }}
            >
              Delegates must have completed Strand 1 in the previous years before they are placed in
              this Strand. Here, you will be taught Biblical theology and how to understand the Old
              Testament in light of Jesus thus, shaping the application of the passage.
              <br /> <br />
              Like Strand 1, you will have the opportunity to write a Bible study on a selected Old
              Testament passage. On the last day of NextGen, you will have the opportunity to
              present your study to the rest of your strand group.
            </Typography>
          </MotionInView>
        </Grid>
      </Grid>

      <Grid container  sx={{ mt: 10 }}>
        <Grid item md={6} xs={12} sx={{ mb:{ xs: 3 } }}>
          <Typography
            sx={{
              color: (theme) => (theme.palette.mode === 'light' ? 'text.primary' : 'common.white'),
              // fontSize: 19,
              textAlign: { xs: 'left', md: 'right' },
              mr: { md: 5 },
              textTransform: 'uppercase',
            }}
            variant="h3"
          >
            Strand 3 <br />
            Systematic Theology
            <hr />
          </Typography>
        </Grid>
        <Grid item md={6}>
          <MotionInView variants={varFade().inUp}>
            <Typography
              sx={{
                mx: 'auto',
                maxWidth: 630,
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'text.primary' : 'common.white',
                fontSize: 19,
                textAlign: 'left',
              }}
            >
              Delegates must have completed Strand 1 and Strand 2 in the previous years before they
              are placed in this Strand. You will be taught how to think about topics (e.g. Social
              Justice) in a gospel-centred manner. Develop your skills in systematic theology so
              that you can effectively pull together what the whole Bible has to say about
              particular topic.
              <br /> <br />
              Like Strand 1 and 2, you will have the opportunity to write a Bible study on this
              selected topic. On the last day of NextGen, you will have the opportunity to present
              your findings to the rest of your strand group.
            </Typography>
          </MotionInView>
        </Grid>
      </Grid> */}
      <Box sx={{ position: 'relative' }}>
        <CarouselArrows filled onNext={handleNext} onPrevious={handlePrevious}>
          <Slider ref={carouselRef} {...settings}>
            {_carouselsMembers.map((member) => (
              <MotionInView key={member.id} variants={varFade().in} sx={{ px: 1.5, py: 10 }}>
                <MemberCard member={member} />
              </MotionInView>
            ))}
          </Slider>
        </CarouselArrows>
      </Box>
      {/* <Button
        variant="outlined"
        color="inherit"
        size="large"
        endIcon={<Iconify icon={'ic:round-arrow-right-alt'} width={24} height={24} />}
        sx={{ mx: 'auto' }}
      >
        View all team members
      </Button> */}
    </Container>
  );
}

// ----------------------------------------------------------------------

type MemberCardProps = {
  member: {
    id: string;
    name: string;
    role: string | undefined;
    avatar: string;
  };
};

function MemberCard({ member }: MemberCardProps) {
  const { name, role, avatar } = member;
  return (
    <Card key={name} sx={{ p: 1 }}>
      {/* <Typography variant="subtitle1" sx={{ mt: 2, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2, color: 'text.secondary' }}>
        {role}
      </Typography> */}
      <Image src={avatar} ratio="16/9" sx={{ borderRadius: 1.5 }} />
      {/* <Stack alignItems="center" sx={{ mt: 2, mb: 1 }}>
        <SocialsButton sx={{ color: 'action.active' }} />
      </Stack> */}
    </Card>
  );
}
